
export const NewRelicScript = (env, onLoadCallback, onErrorCallback) => {
    const createScript = (scriptPath) => {
        let script = document.createElement("script");
        script.setAttribute("src", scriptPath);
        script.setAttribute("async", "true");
        script.setAttribute("id", "new-relic-script");
        
        // Execute provided onload callback
        script.onload = function() {
            console.log("New Relic script loaded successfully");
            if (typeof onLoadCallback === 'function') {
                onLoadCallback();
            }
        };

        // Execute provided onerror callback
        script.onerror = function(error) {
            console.error("Failed to load New Relic script");
            if (typeof onErrorCallback === 'function') {
                onErrorCallback(error);
            }
        };

        document.body.appendChild(script);
    };

    if(env === "production") {
        createScript("/newrelic-script-injection.js");
    } else {
        createScript(`/newrelic-script-injection-${env}.js`);
    }
}
