import React, { useState, useEffect } from 'react'
import {
    PnmacTextInput,
    PnmacUIButton,
    PnmacPaper,
    PnmacAppBar,
    PnmacCheckBox,
    PnmacTermsModal,
    AppActions,
    AppEvents,
    PnmacInputMask,
    PnmacMediaQuery,
    BannerView,
    PnmacText,
    withPnmacContext,
    PnmacAppbarHeader,
    PnmacAppbarContent,
    Hoverable,
    isSubServicingLoan
} from 'pnmac-universal-components'
import MenuIcon from '@material-ui/icons/Menu';
import { View, Text, Image, Platform, TouchableOpacity, ScrollView } from "react-native";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FadeLoader from "react-spinners/FadeLoader";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Footer from './Footer.js'
import { checkForAllNumbers, getActiveFeatures } from '../utils'
import moment from 'moment'
import StepsIndicator from "./StepsIndicator/StepsIndicator";

const { CHANGE_LANGUAGE, LOAN_NUMBERS, LOG_IN } = AppActions
const { TrackerHitTypeLID_ENUM, TrackerActionLID_ENUM, TrackerCategoryLID_ENUM, UserLanguage_ENUM } = AppEvents

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        lineHeight: 1.5
    },
}, { index: 1 })(Button);

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const AllSetView = ({ matches, theme, onPressContinue, translations, layout }) => {

    const largeMedium = (matches.large || matches.medium);
    const small = matches.small;

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const phoneBackground = largeMedium ? require('../assets/phone-background.png') : require('../assets/phone-background-mobile.png');

    return (
        <>
            <PnmacPaper
                containerStyles={{
                    padding: 10,
                    borderRadius: 10,
                    borderColor: "#efefef",
                    borderWidth: 1,
                    display: "flex",
                    backgroundColor: "white",
                    alignItems: "center",
                    shadowOffset: { width: 5, height: 5 },
                    shadowColor: "#efefff",
                    shadowOpacity: 0.5,
                    shadowRadius: 10,
                    elevation: 1,
                    marginTop: 15,
                    maxWidth: 1022,
                    marginLeft: largeMedium ? 0 : 28,
                    marginRight: largeMedium ? 0 : 28,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: largeMedium ? "row" : "column",
                        width: "100%",
                        height: largeMedium ? 370.49 : 570,
                        maxWidth: largeMedium ? 1022 : 371,
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: 38.3,
                        paddingRight: 46.2,
                        marginLeft: largeMedium ? 0 : 28,
                        marginRight: largeMedium ? 0 : 28,
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <PnmacText style={{ ...theme.pageTitle, marginTop: largeMedium ? 0 : 24, textTransform: 'unset', textAlign: largeMedium ? "left" : "center", fontFamily: "Montserrat", fontWeight: "600", fontSize: largeMedium ? 40 : 30 }}>{translations.AllSet}</PnmacText>
                        <PnmacText style={{ ...theme.notificationText, marginTop: largeMedium ? 20 : 11.4, textAlign: largeMedium ? "left" : "center", fontSize: largeMedium ? 16 : 16, fontFamily: "HalenoirCompactText-Medium", color: "#333333" }}>
                            {translations.LoginAnytime}
                            {" "}<a href="pennymac.com/login" style={{ ...theme.notificationText, color: "#117AAA", textDecoration: "underline", textDecorationColor: "#117AAA", textDecorationLine: "solid", fontSize: largeMedium ? 16 : 18, fontFamily: "HalenoirCompactText-Medium" }}>pennymac.com/login</a>
                            {" "}{translations.OnOurApp}
                        </PnmacText>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: largeMedium ? "start" : "center"
                            }}>
                            <PnmacUIButton
                                disabled={false}
                                style={{ width: 253.28, backgroundColor: "#003087", marginTop: largeMedium ? 20 : 48.1, padding: 5 }}
                                mode="contained"
                                loading={false}
                                onPress={onPressContinue}
                            >
                                <PnmacText
                                    style={{ fontFamily: "Montserrat", color: "white", fontWeight: "bold", textTransform: 'uppercase' }}
                                    idTag="Register">
                                    {translations.GoToMyAccount}
                                </PnmacText>

                                <img alt="Chevron right" style={{ height: 22, width: 11, marginLeft: 12.6 }} src={require("../assets/chevron-right.png")} />
                            </PnmacUIButton>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: largeMedium ? "start" : "center",
                        width: 287.64, height: 280.92,
                        marginLeft: largeMedium ? 10 : 0,
                        marginTop: largeMedium ? 0 : 38
                    }}>
                        <img style={{ width: 287.64, height: 280.92 }} src={require('../assets/desk-chair.svg')} />
                    </div>
                </div>
            </PnmacPaper>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: largeMedium ? 42.5 : 30.2,
                    position: "relative",
                    maxWidth: 1022,
                }}
            >
                <img
                    src={phoneBackground}
                    style={{
                        maxWidth: 1022,
                        width: largeMedium ? "100%" : (windowDimensions.width <= 390 ? 312 : "100%"),
                        marginLeft: largeMedium ? 80 : 30,
                        marginRight: largeMedium ? 80 : 30,
                        objectFit: "cover"
                    }}
                />

                <div
                    style={{
                        position: "absolute",
                        top: 20,
                        left: largeMedium ? 20 : (windowDimensions.width <= 390 ? 40 : 40),
                        maxWidth: largeMedium ? 550 : 300
                    }}
                >
                    <PnmacText numberOfLines={2} style={{ ...theme.pageTitle, maxWidth: windowDimensions.width < 390 ? "80%" : "100%", color: "white", textTransform: 'unset', fontFamily: "Montserrat", fontSize: largeMedium ? 40 : (windowDimensions.width <= 390 ? 15 : 23), fontWeight: "600" }}>{translations.DownloadPennymac}</PnmacText>
                    <PnmacText style={{ ...theme.subtitleText, color: "white", width: largeMedium ? "100%" : 200, textTransform: 'unset', marginTop: largeMedium ? 10 : 0, fontFamily: "HalenoirCompactText-Medium", fontSize: largeMedium ? 16 : (windowDimensions.width <= 390 ? 12 : 15) }}>{translations.UseSameLogin}</PnmacText>
                </div>

                <div
                    style={{
                        position: "absolute",
                        bottom: largeMedium ? 10 : (windowDimensions.width <= 390 ? 10 : 10),
                        left: largeMedium ? 20 : (windowDimensions.width <= 390 ? 40 : 40),
                        maxWidth: largeMedium ? 300 : 250,
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <a href='https://apps.apple.com/us/app/pennymac-mobile/id1349950272' style={{ marginBottom: 5 }}>
                        <img src={require("../assets/app-store.png")} style={{ width: largeMedium ? 150 : 100 }} />
                    </a>

                    <a href='https://play.google.com/store/apps/details?id=com.pennymac.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1'>
                        <img src={require("../assets/google-play.png")} style={{ width: largeMedium ? 150 : 100 }} />
                    </a>
                </div>
            </div>

        </>
    );
}

function MiniDrawer(props) {

    let { translations, matches, layout } = props
    const [hoveringLang, setHoveringLang] = useState(false)
    const [hoveringContact, setHoveringContact] = useState(false)
    const [hoveringLogout, setHoveringLogout] = useState(false)

    
    return (
        <header className="border-bottom-1">
            <div>
                <PnmacAppbarHeader
                    style={{ height: 85, backgroundColor: 'white' }}
                >
                    {!matches.small && (
                        <PnmacAppbarContent
                            title={
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    flexGrow: 1
                                }}> 
                                    <img alt="logo" style={{width: 300}} src={(layout.logoUrl)}/>
                                </div>
                            }
                        />
                    )}


                    <div style={{
                        paddingRight: 15,
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: matches.small ? "100%" : "initial",
                        marginTop: matches.small ? 10 : 0,
                        marginBottom: matches.small ? 10 : 0,
                    }}>
                        {matches.small ?
                            <Hoverable
                                onHoverIn={() => setHoveringLang(true)}
                                onHoverOut={() => setHoveringLang(false)}
                            >
                                <TouchableOpacity
                                    style={hoveringLang ? styles.hoverTabBg : styles.defaultTabBg}
                                    onPress={() => {

                                        props.dispatch({
                                            type: CHANGE_LANGUAGE,
                                            lang: layout.language == "en" ? 'es' : 'en', dontPost: true
                                        })

                                    }}>{layout.language == "en" ?
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <Image style={{
                                                height: 20,
                                                width: 20,
                                                marginBottom: Platform.OS === 'android' ? 3 : 4
                                            }} source={require("../assets/globe.svg")} />
                                            <Text style={styles.defaultTab}>Español</Text>
                                        </View>
                                        :
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <Image style={{
                                                height: 20,
                                                width: 20,
                                                marginBottom: Platform.OS === 'android' ? 3 : 4
                                            }} source={require("../assets/globe.svg")} />
                                            <Text style={styles.defaultTab}>English</Text>
                                        </View>}
                                </TouchableOpacity>
                            </Hoverable> :
                            <TouchableOpacity
                                onPress={() => {

                                    props.dispatch({
                                        type: CHANGE_LANGUAGE,
                                        lang: layout.language == "en" ? 'es' : 'en', dontPost: true
                                    })

                                }}
                            >{layout.language == "en" ?
                                <BootstrapButton style={{
                                    ...styles.defaultTab,
                                    fontSize: matches.small ? 12 : 16,
                                    padding: matches.small ? '0px 9px' : '6px 12px',
                                }}>Español</BootstrapButton> :
                                <BootstrapButton {...props} style={{
                                    ...styles.defaultTab,
                                    fontSize: matches.small ? 12 : 16,
                                    padding: matches.small ? '0px 9px' : '6px 12px',
                                }}>English</BootstrapButton>}
                            </TouchableOpacity>
                        }

                        {matches.small ? <Hoverable
                            onHoverIn={() => setHoveringLogout(true)}
                            onHoverOut={() => setHoveringLogout(false)}
                        >
                            <TouchableOpacity style={hoveringLogout ? styles.hoverTabBg : styles.defaultTabBg}
                                onPress={() => {
                                    props.dispatch({ type: LOG_IN, logedIn: false })
                                    window.location.href = "/oauth/log_out";
                                }}
                            >
                                <Image style={{ height: 20, width: 20, marginBottom: 5 }}
                                    source={require("../assets/right-from-bracket.svg")} />
                                <Text style={styles.defaultTab}>{translations.logout}</Text>
                            </TouchableOpacity>
                        </Hoverable> :
                            <PnmacUIButton
                                mode={'contained'}
                                onClick={() => {
                                    props.dispatch({ type: LOG_IN, logedIn: false })
                                    window.location.href = "/oauth/log_out";
                                }}
                            >
                                <PnmacText style={{ fontFamily: "Montserrat", color: "white", fontWeight: "bold", textTransform: 'uppercase' }}>
                                    {translations.logout}
                                </PnmacText>
                            </PnmacUIButton>}
                    </div>

                </PnmacAppbarHeader>
            </div>
        </header>
    );
}

class Registration extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loanId: "",
            dob: "",
            valid: false,
            ssn: "",
            lastFour: "",
            propertyZip: "",
            checked: false,
            termsAndConditions: false,
            electronicCommunicaitons: false,
            lastFourError: false,
            dobError: false,
            openedTerms: false,
            openedElectronics: false,
            timeOpen: false,
            loanError: false,
            loanErrorMsg: "Please input a valid 10 digit number",
            modalOpenTe: false,
            modalOpenEl: false,
            loading: false,
            errorType: 1,
            modalData: {
                cancel: null,
                accept: null,
                header: null,
                node: "",
                link: null,
                content: null,
                modelId: null,
                title: null
            },
            registerComplete: false,
            responseData: null, 
            callbackPath: ""

        }
    }


    setValue = (value, property) => {

        this.setState({ [property]: value })
    }

    postRegistration = () => {
        let { loanId, lastFour, termsAndConditions, dob, electronicCommunicaitons, propertyZip } = this.state
        let request = JSON.stringify({
            loanId: parseInt(loanId),
            lastFour: lastFour,
            propertyZip: propertyZip,
            dob: moment(dob).format("YYYY-MM-DD"),
            electronicCommunicaitons: electronicCommunicaitons,
            termsAndConditions: termsAndConditions
        })
        fetch('api/registration/association', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${this.props.layout.token}`
            },
            body: request
        }).then(res => res.json()).then((data) => {  
            if (data?.errorType == 1) {
                this.setState({ registerComplete: true, responseData: data })
            } else {
                this.setState({ lastFourError: (data.lastFourError ?? true), dobError: (data.dobError ?? true), loanError: (data.loanError ?? true), propertyZipError: (data.propertyZipError ?? true), loading: false, errorType: data.errorType })
            }
        }).catch(err => {
            this.setState({ loading: false })
        });
    }


    static getDerivedStateFromProps(props, state) {

        let { loanId, lastFour, dob, termsAndConditions, electronicCommunicaitons, propertyZip } = state
        if (
            checkForAllNumbers(lastFour, "4") &&
            checkForAllNumbers(loanId, "10") &&
            checkForAllNumbers(propertyZip, "5") &&
            termsAndConditions &&
            electronicCommunicaitons &&
            moment(dob).isValid()
        ) {
            return {
                ...state,
                valid: true
            }
        } else {
            return {
                ...state,
                valid: false
            }
        }

    }

    componentDidMount() {

        this.props.authenticate(true)

        fetch(`api/account/callbackPath`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${this.props.layout.token}`
            },
        }).then((data) => data.text()).then((path) => {
            this.setState({ callbackPath: path })
        }).catch(err => {
            console.log(err)

        });

    }

    goToAccount = () => {
        if (this.state.responseData && this.state.responseData.mobile) {
            window.location.href = `/?ids_token=${this.props.layout.token}&&loan_numbers=${this.state.responseData.ln}&&ids_user=${this.state.responseData.idsUserId}`
        } else {
            this.props.dispatch({ type: LOAN_NUMBERS, loanNumbers: this.state.responseData.ln, token: this.state.responseData.token })
            this.props.authenticate()
            this.props.history.push(`/?ids_token=${this.props.layout.token}&&loan_numbers=${this.state.responseData.ln}`)
        }
    }

    render() {
        let { loanId, lastFour, lastFourError, loanError, dob, dobError, timeOpen, errorType, propertyZip, propertyZipError } = this.state
        let { translations, layout, theme } = this.props
        const isFullURLContainsSubservicing = this.state.callbackPath.includes("subservicing");

        if (this.props.layout.logedIn && translations.AgreeAndContinue) {
            return (
                <PnmacMediaQuery queries={{
                    small: "(max-width: 599px)",
                    medium: "(min-width: 600px) and (max-width: 1199px)",
                    large: "(min-width: 1200px)"
                }}>
                    {matches => (
                        <div>
                            {!layout.wrapedMobile && <MiniDrawer matches={matches} {...this.props} isFullURLContainsSubservicing={isFullURLContainsSubservicing} />}

                            {errorType == 6 ?
                                <BannerView type={'error'} style={{
                                    margin: 10,
                                }}>
                                    <div className={'htmlToNative'} dangerouslySetInnerHTML={{ __html: translations?.InvalidDataPointsUserReg }} />
                                </BannerView>
                                : errorType == 3 ?
                                    <BannerView type={'warning'} style={{
                                        margin: 10,
                                    }}>
                                        <div className={'htmlToNative'} dangerouslySetInnerHTML={{ __html: translations?.NoActiveLoan }} />
                                    </BannerView>
                                    : errorType == 5 ?
                                        <BannerView type={'warning'} style={{
                                            margin: 10,
                                        }}>
                                            <div className={'htmlToNative'} dangerouslySetInnerHTML={{ __html: translations?.LockOut }} />
                                        </BannerView>
                                        : errorType == 2 ?
                                            <BannerView type={'error'} style={{
                                                margin: 10,
                                            }}>
                                                <div className={'htmlToNative'} dangerouslySetInnerHTML={{ __html: translations?.InvalidDataPointsUserReg }} />
                                            </BannerView> :
                                            errorType == 4 ?
                                                <BannerView type={'error'} style={{
                                                    margin: 10,
                                                }}>
                                                    <div className={'htmlToNative'} dangerouslySetInnerHTML={{ __html: translations?.DefaultErrorMessage }} />
                                                </BannerView> : null
                            }
                           
                            <PnmacTermsModal
                                cancelTerms={() => null}
                                open={this.state.modalOpenTe}
                                ACE
                                callbackOnAccept={(data) => {
                                    this.setState({
                                        termsAndConditions: true, modalOpenTe: false, openedTerms: true
                                    })
                                }}
                                enject
                                handleClose={() => this.setState({ modalOpenTe: false })}
                                data={this.state.modalData}
                            />

                            <PnmacTermsModal
                                cancelTerms={() => null}
                                open={this.state.modalOpenEl}
                                ACE
                                callbackOnAccept={(data) => this.setState({
                                    electronicCommunicaitons: true, modalOpenEl: false, openedElectronics: true

                                })}
                                enject
                                handleClose={() => this.setState({ modalOpenEl: false })}
                                data={this.state.modalData}
                            />

                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: '100%' }}>
                                <div style={{ display: "flex", width: '100%', maxWidth: 1200, marginTop: 50, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                    <StepsIndicator
                                        matches={matches}
                                        completed={
                                            this.state.registerComplete
                                        }
                                        steps={[
                                            { stepNumber: 1, stepLabel: translations.LoginSetup },
                                            { stepNumber: 2, stepLabel: translations.ContactInfo },
                                            { stepNumber: 3, stepLabel: translations.SecurityQuestions },
                                            { stepNumber: 4, stepLabel: translations.LoanInfo },
                                        ]}
                                        currentStep={3}
                                    />
                                </div>
                                <div style={{ width: matches.large || matches.medium ? "95%" : '95%', maxWidth: 1022, paddingTop: 50, paddingBottom: 50, alignItems: "center", justifyContent: "center", borderRadius: 7 }}>

                                    {!this.state.registerComplete && <PnmacPaper
                                        containerStyles={{
                                            padding: 10,
                                            borderRadius: 10,
                                            borderColor: "#efefef",
                                            borderWidth: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            shadowOffset: { width: 5, height: 5 },
                                            shadowColor: "#efefff",
                                            shadowOpacity: 0.5,
                                            shadowRadius: 10,
                                            elevation: 1,
                                            marginLeft: 10,
                                            marginRight: 10,
                                            marginTop: 15,
                                        }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "83%",
                                            justifyContent: "center",
                                            marginTop: 20
                                        }}
                                        >
                                            <PnmacText style={{ ...theme.pageTitle, textAlign: "center", textTransform: 'unset', fontSize: (matches.large || matches.medium) ? 40 : 30, fontFamily: "Montserrat", fontWeight: "600" }}>{translations.ConfirmLoanInfo}</PnmacText>
                                            <PnmacText style={{ ...theme.formBody, textAlign: "center", marginTop: 18, fontFamily: "HalenoirCompactText-Medium", color: "#333333", fontSize: (matches.large || matches.medium) ? 18 : 16 }}>
                                                {translations.VerifyAdditionalDetails}
                                            </PnmacText>
                                        </div>

                                        {(matches.large || matches.medium) && (<View
                                            style={{
                                                width: "95%",
                                                height: 10,
                                                backgroundColor: "#F5F9FB",
                                                marginTop: 52.1,
                                                marginLeft: 42.5,
                                                marginRight: 42.5
                                            }}
                                        />)}

                                        <div style={{
                                            display: "flex",
                                            flexDirection: matches.small ? "column" : "row",
                                            width:  matches.small ? "83%" : "100%",
                                            justifyContent: "center",
                                            marginTop: 20
                                        }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column", width:  matches.small ? "83%" : "100%", marginRight: matches.small ? 0 : 20 }}>
                                                <PnmacText style={{ ...theme.formBody, marginBottom: 9.9, fontSize: 14, fontFamily: "HalenoirCompactText-Medium", color: "#5C5C5C" }}>{translations.LoanNumber}</PnmacText>
                                                <PnmacTextInput
                                                    textAlign={'left'}
                                                    mode="outlined"
                                                    style={{ width: matches.small ? "83%" : "100%", height: 35 }}
                                                    placeholderTextColor="gray"
                                                    disabled={errorType == 5}
                                                    value={loanId}
                                                    maxLength={10}
                                                    error={loanError}
                                                    onChangeText={text => {
                                                        if (checkForAllNumbers(text)) {
                                                            this.setValue(text, "loanId")
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        if (!checkForAllNumbers(this.state.loanId, "10")) {
                                                            this.setState({ loanError: true });

                                                        } else {
                                                            this.setState({ loanError: false })
                                                        }
                                                    }}
                                                />
                                                {!this.state.loanError && (
                                                    <PnmacText style={{ ...theme.formBody, fontSize: 13, marginTop: 12.9, fontFamily: "HalenoirCompactText-Regular", color: "#757575" }}>{translations.YourLoanNumberIsWelcomeLetter}</PnmacText>
                                                )}
                                                {this.state.loanError && (
                                                    <PnmacText style={{ ...theme.formBodyError, fontSize: 10, color: "#FC5350" }}>{translations.ValidLoanId}</PnmacText>
                                                )}
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "column", width:  matches.small ? "83%" : "100%", marginLeft: matches.small ? 0 : 20, marginTop: matches.small ? 20 : 0 }}>
                                                <PnmacText style={{ ...theme.formBody, marginBottom: 9.9, fontSize: 14, fontFamily: "HalenoirCompactText-Medium", color: "#5C5C5C" }}>{translations.PropertyZIPCode}</PnmacText>
                                                <PnmacTextInput
                                                    textAlign={'left'}
                                                    mode="outlined"
                                                    style={{ marginBottom: 5, width: matches.small ? "83%" : "100%", height: 35 }}
                                                    disabled={errorType == 5}
                                                    value={propertyZip}
                                                    maxLength={5}
                                                    error={propertyZipError}
                                                    onChangeText={text => {
                                                        if (checkForAllNumbers(text)) {
                                                            this.setValue(text, "propertyZip")
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        if (!checkForAllNumbers(this.state.propertyZip, "5")) {
                                                            this.setState({ propertyZipError: true });
                                                        } else {
                                                            this.setState({ propertyZipError: false })
                                                        }
                                                    }}
                                                />

                                                {this.state.propertyZipError && (
                                                    <PnmacText style={{ ...theme.formBodyError, fontSize: 10, color: "#FC5350" }}>{translations.EnterPropertyZip}</PnmacText>
                                                )}
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: matches.small ? "column" : "row",
                                           // width: "83%",
                                            width: matches.small ? "83%" : "100%",
                                            justifyContent: "center",
                                            marginTop: 20,
                                        }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column", width:  matches.small ? "83%" : "100%", marginRight: matches.small ? 0 : 20 }}>
                                                <PnmacText style={{ ...theme.formBody, fontSize: 14, marginBottom: 9.9, fontFamily: "HalenoirCompactText-Medium", color: "#5C5C5C" }}>{translations.LastFourSSN}</PnmacText>
                                                <PnmacTextInput
                                                    textAlign={'left'}
                                                    mode="outlined"
                                                    style={{ marginBottom: 5, width: matches.small ? "83%" : "100%", height: 35 }}
                                                    disabled={errorType == 5}
                                                    value={lastFour}
                                                    maxLength={4}
                                                    error={lastFourError}
                                                    onChangeText={text => {

                                                        if (checkForAllNumbers(text)) {
                                                            this.setValue(text, "lastFour")
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        if (!checkForAllNumbers(this.state.lastFour, "4")) {
                                                            this.setState({ lastFourError: true });
                                                        } else {
                                                            this.setState({ lastFourError: false })
                                                        }
                                                    }}
                                                />
                                                {this.state.lastFourError && (
                                                    <PnmacText style={{ fontSize: 10, color: '#FC5350' }}>{translations.EnterLast4DigitsSSN}</PnmacText>
                                                )}
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "column", width:  matches.small ? "83%" : "100%", marginLeft: matches.small ? 0 : 20, marginTop: matches.small ? 20 : 0 }}>
                                                <PnmacText style={{ ...theme.formBody, fontSize: 14, marginBottom: 9.9, fontFamily: "HalenoirCompactText-Medium", color: "#5C5C5C" }}>{translations.DateOfBirth}</PnmacText>
                                                                                                                                                
                                                <PnmacTextInput
                                                    textAlign="left"
                                                    mode="outlined"
                                                    style={{ marginBottom: 5, width: matches.small ? "83%" : "100%", height: 35 }}
                                                    value={this.state.dob}
                                                    error={dobError}
                                                    disabled={errorType == 5}
                                                    onChangeText={text => {
                                                        this.setValue(text, "dob")
                                                    }}
                                                    onBlur={() => {
                                                        if (!moment(this.state.dob, "MM/DD/YYYY", true).isValid()) {
                                                            this.setState({ dobError: true });

                                                        } else {
                                                            this.setState({ dobError: false })
                                                        }
                                                    }}
                                                    render={(props) => {
                                                        let { style, translationText, multiline, toggleTheme, underlineColorAndroid, editable, selectionColor, toggleLanguage, placeholderTextColor, onChangeText, testID, ...rest } = props
                                                        let st = style.reduce(function (a, i) {
                                                            return {
                                                                ...a,
                                                                ...i
                                                            }
                                                        })
                                                        st.paddingTop = 0
                                                        st.backgroundColor = 'unset'
                                                        st.border = 'unset'

                                                        return (
                                                            <>
                                                                <PnmacInputMask style={{ ...st, padding: '5px 0px 5px 10px' }} value={rest.value} mask="99/99/9999" {...rest} onChange={(e) => {

                                                                    onChangeText(e.target.value)
                                                                }} />

                                                            </>
                                                        )
                                                    }}
                                                />
                                                {this.state.dobError && (
                                                    <PnmacText style={{ fontSize: 10, color: '#FC5350' }}>{translations.EnterDateOfBirth}</PnmacText>
                                                )}
                                            </div>
                                        </div>

                                        <BannerView style={{ width: "83%", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 23.8, backgroundColor: "#EDFAFF", borderColor: "#CBEAF5" }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                width: '100%',
                                                borderRadius: this.state.termsAndConditions ? null : 5,
                                            }}>
                                                <div onClick={() => {
                                                    if (translations.AgreeAndContinue && !this.state.openedTerms) {
                                                        this.setState({
                                                            modalOpenTe: true,
                                                            modalData: {
                                                                ...this.state.modalData,
                                                                cancel: translations.Close,
                                                                node: "termsAndConditions",
                                                                accept: translations.AgreeAndContinue,
                                                                header: translations.TermsContentDescription,
                                                                link: `<a style="cursor: pointer" href="/Home/Terms/193?class=daf"  target="_blank">${translations.ViewAsSeparatePage}</a>`,
                                                                content: translations.TermsContent,
                                                                title: translations.TermsContentDescription
                                                            }
                                                        })
                                                    } else {
                                                        this.setState({ termsAndConditions: !this.state.termsAndConditions })
                                                    }
                                                }}>
                                                    <input
                                                        style={{ width: 15, height: 15, marginRight: 11, backgroundColor: "white" }}
                                                        type="checkbox"
                                                        checked={this.state.termsAndConditions}
                                                        onChange={() => { }}
                                                        role="checkbox"
                                                        aria-label="Checkbox"
                                                    />
                                                </div>
                                                <div onClick={() => {
                                                    this.setState({
                                                        modalOpenTe: true,
                                                        modalData: {
                                                            ...this.state.modalData,
                                                            cancel: translations.Close,
                                                            node: "termsAndConditions",
                                                            accept: translations.AgreeAndContinue,
                                                            header: translations.TermsContentDescription,
                                                            link: `<a style="cursor: pointer" href="/Home/Terms/193?class=daf"  target="_blank">${translations.ViewAsSeparatePage}</a>`,
                                                            content: translations.TermsContent,
                                                            title: translations.TermsContentDescription
                                                        }
                                                    })

                                                }}>
                                                    
                                                    <PnmacText
                                                        style={{ ...theme.subtitleText, color: '#5C5C5C', fontSize: 14, fontFamily: "HalenoirCompactText-Medium" }}
                                                    >
                                                        {`${translations.IhaveReadAndAgree}`} <span style={{ ...theme.subtitleText, color: "#0871A0", textDecorationLine: "underline", textDecorationStyle: "solid", textDecorationColor: "#0871A0", }}>{translations.TermsConditionsLink}</span>
                                                    </PnmacText>
                                                </div>
                                            </div>
                                        </BannerView>

                                        <BannerView style={{ width: "83%", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 18, backgroundColor: "#EDFAFF", borderColor: "#CBEAF5" }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                width: '100%',
                                                borderRadius: this.state.electronicCommunicaitons ? null : 5,

                                            }}>
                                                <div onClick={() => {
                                                    if (translations.AgreeAndContinue && !this.state.openedElectronics) {
                                                        this.setState({
                                                            modalOpenEl: true,
                                                            modalData: {
                                                                ...this.state.modalData,
                                                                cancel: translations.Close,
                                                                accept: translations.AgreeAndContinue,
                                                                node: "electronicCommunicaitons",
                                                                header: translations.ElectronicCommunicationsDescription,
                                                                link: `<a style="cursor: pointer" href="/Home/Terms/196?class=daf" target="_blank">${translations.ViewAsSeparatePage}</a>`,
                                                                content: translations.ElectronicCommunicationsContent,
                                                                title: translations.ElectronicCommunicationsDescription
                                                            }
                                                        })
                                                    } else {
                                                        this.setState({ electronicCommunicaitons: !this.state.electronicCommunicaitons })
                                                    }
                                                }}>
                                                    <input
                                                        style={{ width: 15, height: 15, marginRight: 11, backgroundColor: "white" }}
                                                        type="checkbox"
                                                        checked={this.state.electronicCommunicaitons}
                                                        onChange={() => { }}
                                                        role="checkbox"
                                                        aria-label="Checkbox"
                                                    />
                                                </div>
                                                <div onClick={() => {
                                                    this.setState({
                                                        modalOpenEl: true,
                                                        modalData: {
                                                            ...this.state.modalData,
                                                            cancel: translations.Close,
                                                            accept: translations.AgreeAndContinue,
                                                            node: "electronicCommunicaitons",
                                                            header: translations.ElectronicCommunicationsDescription,
                                                            link: `<a style="cursor: pointer" href="/Home/Terms/196?class=daf" target="_blank">${translations.ViewAsSeparatePage}</a>`,
                                                            content: translations.ElectronicCommunicationsContent,
                                                            title: translations.ElectronicCommunicationsDescription
                                                        }
                                                    })

                                                }}>
                                                    <PnmacText
                                                        style={{ ...theme.subtitleText, color: '#5C5C5C', fontSize: 14, fontFamily: "HalenoirCompactText-Medium" }}
                                                    >
                                                        {`${translations.IhaveReadAndAgree} `} <span style={{ ...theme.subtitleText, color: "#0871A0", textDecorationLine: "underline", textDecorationStyle: "solid", textDecorationColor: "#0871A0" }}>Electronic Communications Disclosure and Consent Agreement.</span>
                                                    </PnmacText>
                                                </div>
                                            </div>
                                        </BannerView>

                                        {(matches.large || matches.medium) && (<View
                                            style={{
                                                width: "95%",
                                                height: 10,
                                                backgroundColor: "#F5F9FB",
                                                marginTop: 52.1,
                                                marginLeft: 42.5,
                                                marginRight: 42.5
                                            }}
                                        />)}

                                        <div
                                            style={{
                                                width: '100%',
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                display: "flex",
                                                margin: 20
                                            }}
                                        >

                                            <PnmacUIButton
                                                disabled={!this.state.valid || errorType == 5}
                                                style={{ padding: 5, width: (matches.large || matches.medium) ? 253.28 : '83%' }}
                                                mode="contained"
                                                loading={this.state.loading}
                                                onPress={() => {
                                                    this.setState({ 
                                                        loading: true,
                                                        errorType: 1,
                                                        lastFourError: false,
                                                        dobError: false,
                                                        loanError: false,
                                                        propertyZipError: false }, () => {
                                                        this.postRegistration()
                                                    })
                                                }}

                                            >
                                                <PnmacText
                                                    style={{ fontFamily: "Montserrat", color: "white", fontWeight: "bold", textTransform: 'uppercase' }}
                                                    idTag="Register">
                                                    {translations.CompleteRegistration}
                                                </PnmacText>

                                                <img alt="Chevron right" style={{ height: 22, width: 11, marginLeft: 12.6 }} src={require("../assets/chevron-right.png")} />
                                            </PnmacUIButton>
                                        </div>

                                        {matches.small &&
                                            (<TouchableOpacity onPress={() => window.history.back()}>
                                                <div
                                                    style={{
                                                        width: (matches.large || matches.medium) ? 253.28 : '83%',
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        margin: 20,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        paddingRight: 20
                                                    }}
                                                >
                                                    <img alt="Chevron left" style={{ height: 22, width: 11, marginRight: 12.6 }} src={require("../assets/chevron-left.png")} />
                                                    <PnmacText
                                                        style={{ fontFamily: "Montserrat", color: "#5C5C5C", fontWeight: "bold", textTransform: 'uppercase' }}
                                                        idTag="Back">
                                                        {translations.Back}
                                                    </PnmacText>
                                                </div>
                                            </TouchableOpacity>)}

                                    </PnmacPaper>}
                                    {this.state.registerComplete && <AllSetView layout={layout} translations={translations} matches={matches} theme={theme} onPressContinue={this.goToAccount} />}
                                </div>
                            </div>
                            <Footer {...this.props} registration={true} matches={matches} />
                        </div>
                    )}
                </PnmacMediaQuery>

            )
        }
        return (
            <div style={{ marginTop: '25%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", fontSize: "32px", fontWeight: "bold" }}>
                <div>
                    <div>
                        <FadeLoader
                            size={200}
                            color={"#003187"}
                            loading={true}
                        />
                    </div>
                </div>
            </div >
        )
    }
}


const mapStateToProps = state => ({
    layout: state,
    translations: state.translations

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
        ...bindActionCreators(AppActions,
            dispatch
        )
    }
}

const styles = {
    selectTab: {
        fontFamily: "HalenoirCompactText-Bold",
        fontSize: 12,
        color: '#117AAA',
        textAlign: 'center',
    },
    defaultTab: {
        fontFamily: "HalenoirCompactText-Medium",
        fontWeight: '500',
        fontSize: 12,
        color: '#1B1B1B',
        textAlign: 'center',
        width: '100%'
    },
    selectTabBg: {
        minWidth: 60,
        maxWidth: 80,
        backgroundColor: '#EAF5F8',
        marginHorizontal: 5,
        borderRadius: 5,
        alignItems: 'center',
        paddingVertical: 10,

    },
    defaultTabBg: {
        paddingVertical: 10,
        minWidth: 60,
        maxWidth: 80,
        height: 60,
        marginHorizontal: 5,
        borderRadius: 10,
        alignItems: 'center',
    },
    hoverTabBg: {
        paddingVertical: 10,
        minWidth: 60,
        maxWidth: 80,
        marginHorizontal: 5,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EAF5F8',
        borderWidth: 1,
        borderColor: '#39AFDD'
    },
}

export default connect(mapStateToProps, mapDispatchToProps)(withPnmacContext(Registration))
