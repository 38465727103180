import 'unfetch/polyfill';
import 'abortcontroller-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'broadcastchannel-polyfill'
import React from 'react';
import { Router } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux'
import { store as s } from 'pnmac-universal-components'
import { AppRegistry } from 'react-native'
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react'
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { getCookie } from './utils.js'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

let env = window.location.hostname ? window.location.hostname.indexOf("-qa") > -1 ? "qa" : window.location.hostname.indexOf("-dev") > -1 ? "development" : window.location.hostname.indexOf("-stg") > -1 ? "staging" : window.location.hostname.indexOf("localhost") == -1 ? "production" : "debug" : "debug"

var urlParams = new URLSearchParams(window.location.search);

let pennyWise = urlParams.has('p3nnywis3');

if (!pennyWise) {
    if (env == "staging") {
        Sentry.init({
            dsn: "https://73fb236729e644a7bc4783900871a845@o504691.ingest.sentry.io/5592858",
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
            environment: pennyWise ? "pennywise_staging" : "staging"
        });
    }

}

var urlParams = new URLSearchParams(window.location.search);


const baseUrl = document.getElementsByTagName('base') && document.getElementsByTagName('base').length > 0 ? document.getElementsByTagName('base')[0].getAttribute('href') : "/";


const history = createBrowserHistory();


const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;


export const store = s(pennyWise, !pennyWise, "correlation")

class ErrorComponent extends React.Component {
    render() {
        return (
            <div className="error-container">
                <h1 className="error-message">Sorry, something went wrong.</h1>
            </div>
        );
    }
}

class AppDemo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
        this.setState({error: error, errorInfo: errorInfo})
    }

    componentDidMount() {
        if (!pennyWise) {
            //unregister()
            if (window.location.hostname.indexOf("customers") > -1) {
                unregister()
            } else {
                registerServiceWorker();
            }

        }

    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {

        return (
            this.state.hasError ? <ErrorComponent error={this.state.error} errorInfo={this.state.errorInfo} /> : (
            <Provider store={store.store}>
                <ConditionalWrapper
                    condition={!pennyWise}
                    wrapper={children => <PersistGate loading={null} persistor={store.persistor}>{children}</PersistGate>}
                >
                    <Router history={history} basename={baseUrl}>
                        <App penny={pennyWise} />
                    </Router>
                </ConditionalWrapper>
            </Provider>)
        )

    }
}



AppRegistry.registerComponent('Pennymac', () => AppDemo)
AppRegistry.runApplication('Pennymac', {
    rootTag: document.getElementById('root'),
})

