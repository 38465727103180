

export function versionCompare(v1, v2, options) {
    var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1?.split('.') || [],
        v2parts = v2?.split('.') || [];

    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }

        if (v1parts[i] == v2parts[i]) {
            continue;
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }

    if (v1parts.length != v2parts.length) {
        return -1;
    }

    return 0;
}
export const maskBankInfo = (value) => {
    if (value) {
        var keep = value.slice(-4)
        return `******${keep}`
    }
    return ""
    
}

export const windowObject = typeof window !== 'undefined' && window ? window : {
    location: {
        pathname: "Screen",
        hostname: "Mobile"
    }
}
export const checkForAllNumbers = (inputtxt, length) => {
    var numbers = /^[0-9]+$/;
    if (inputtxt && inputtxt.toString().match(numbers) || inputtxt == '') {
        if (length && (inputtxt.length == length)) {
            return true
        } else if (length) {
            return false
        }
        return true;
    }
    else {
        return false;
    }
}

export const checkForAllNumbersWithLengthGreater = (inputtxt, length) => {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers) || inputtxt == '') {
        if (length && (inputtxt.length >= length)) {
            return true
        } else if (length) {
            return false
        }
        return true;
    }
    else {
        return false;
    }
}

export const checkForAllNumbersWBetweenMinLengthAndMaxLength = (inputtxt, minlength, maxlength) => {
    var numbers = /^[0-9]+$/;
    if (inputtxt.match(numbers) || inputtxt == '') {
        if (minlength && maxlength && (inputtxt.length >= minlength && inputtxt.length <= maxlength)) {
            return true
        } else if (minlength && maxlength) {
            return false
        }
        return true;
    }
    else {
        return false;
    }
}

export const findUpTag = (el, attr) => {
    while (el.parentNode) {
        el = el.parentNode;
        if (el[attr]) {
            return el;
        }
    }
    return null;
}
export const checkForAllNumbersAndSpecial = (inputtxt, length) => {
    var numbers = length ? new RegExp(`^[0-9]${length}+$`) : /^[0-9]+$/;
    if (inputtxt.match(numbers) || inputtxt == '') {
        return true;
    }
    else {
        return false;
    }

}

export const checkForAllNumbersAndSpecialZip = (inputtxt, length) => {
    var numbers = /^[0-9-]+$/;
    if (length == '-a-zA-Z') {
        numbers = /^[0-9a-zA-Z\-]+$/;
    }
    if (inputtxt.match(numbers) || inputtxt == '') {
        return true;
    }
    else {
        return false;
    }
}
export const checkForZip = (inputtxt) => {
    if (!inputtxt.includes("-")) {
        return true;
    }
    var numbers = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (inputtxt.match(numbers)) {
        return true;
    }
    else {
        return false;
    }
}
export const isValidateZipLengthNew = (inputtxt) => {
    if (inputtxt.includes("-")) {
        return inputtxt.length <= 10;
    }
    else {
        return inputtxt.length <= 9;
    }
}
export const checkForForeignZip = (inputtxt) => {
    if (inputtxt.includes("-") && (inputtxt.charAt(0) == "-" || inputtxt.charAt(9) == "-")) {
        return false;
    } else {
        return true;
    }
}


export const checkForAllNumbersCurrency = (inputtxt) => {
    var numbers = new RegExp(`^[0-9,.]*$`)
    if (inputtxt.match(numbers) || inputtxt == '') {
        return true;
    }
    else {
        return false;
    }

}


export function toCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}
export function checkIfStringHasSpecialChar(_string) {

    for (var i = 0; i < _string.length; i++) {
        if (_string.charCodeAt(i) <= 31 || _string.charCodeAt(i) > 126) {
            return false;
        }
    }
    return true;
}

export const paymentTypes = {
    0: "Unknown",
    201296: "Trial Payment",
    201297: "Principal Reduction",
    201298: "Monthly Payment",
    201299: "Additional Amounts",
    1000145: "Forbearance",
    201309: "AutoPay Monthly"
}

export const paymentsource = {
    0: "Web",
    1: "Telephone",
    2: "Unknown",
    3: "Customer Service Rep",
    4: "AutoPay Monthly",
    5: "AutoPay BiWeekly",
    6: "Mobile"
}

export const accountype = {
    0: "Unknown",
    201286: "Checking",
    201287: "Saving",
    201288: "DebitCard"
}

export const paystatus = {
    0: "Processing",
    201290: "Processing",
    201291: "Scheduled",
    201292: "Complete",
    201293: "Cancelled",
    201294: "Cancelling",
    201295: "Failed",
    1000008: "Processing",
    301296: "Pending",
    201289: "ProcessingPayment"
}

export const isValidatePhoneNumberLength = (data) => {
    let regdata = data.replace(/[^\w]/gi, '')
    return regdata?.length <= 10
}
export const isValidateZipLength = (data) => {
    let regdata = data.replace(/[^\w]/gi, '')
    return regdata?.length <= 10
}

export const currencyFormatter = (locals, style, currency, number) => {
    return new Intl.NumberFormat(locals, {
        style: style,
        currency: currency,
        minimumFractionDigits: 2
    }).format(number)
}

export const percentFormatter = (locals, style, number, digits = 3) => {
    return new Intl.NumberFormat(locals, {
        style: style,
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
    }).format(number)
}

export const loanTerm = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}


export const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}

export const getActiveFeatures = (features, feature, whitelisted) => {
    if (features && features.find(s => s.feature == feature) && features.find(s => s.feature == feature && s.toggled == true)) {
        return true
    }
    return whitelisted || false;
}

export const closingLogOut = async () => {
    await fetch(`/oauth/logout?logout=${true}`)
}

export const showTakeActionDeterminStatus = (status, stat) => {
    const logic = (_status) => "default,bankruptcy,completedtwo".toLowerCase().indexOf(_status?.processStatus?.toLowerCase()) > -1;
    if (stat?.statusTypeIsInTrial || paymentStatus(status, "current", [])?.hasStatus || genericStatusCheck(status, "completeOne", ["servicereleased"])?.hasSubStatus || logic(status)) {
        return true;
    }
    return false;
}

export const socialMediaDeterminStatus = (status, modifcation) => {
    const logic = (_status) => "default,bankruptcy,completedtwo".toLowerCase().indexOf(_status?.processStatus?.toLowerCase()) > -1;
    if (paymentStatus(status, "delinquent", ["2PaymentsPastDue"])?.hasSubStatus || modifcation?.statusTypeIsInTrial || paymentStatus(status, "current", [])?.hasStatus || genericStatusCheck(status, "completeOne", ["servicereleased"])?.hasSubStatus || logic(status)) {
        return true;
    }
    return false;
}


export const loanIsForbearance = (status) => {
    return {
        isForbearance: status?.processStatus?.toLowerCase() == "lossmitigation",
        forbearanceStatus: status?.processPhaseStatus?.toLowerCase()
    }
}



export const loanCompletedOne = (status) => {
    return {
        isCompletedOne: "completedtwo,completedone".toLowerCase().indexOf(status?.processStatus?.toLowerCase()) > -1,
        completedOneStatus: status?.processPhaseStatus?.toLowerCase()
    }
}

export const phazOne = (status) => {
    const logic = (_status) => "default,foreclosure,completedtwo,completedone,delinquentliquidation,hhf,shortSale,deedinlieu".toLowerCase().indexOf(_status?.processStatus?.toLowerCase()) > -1;

    if (ammountPaymentPastDue(status, 3, true, false) || genericStatusCheck(status, "completeone", ["servicereleased"]).hasSubStatus || loanIsForbearance(status)?.isForbearance || logic(status)) {
        if (genericStatusCheck(status, "bankruptcy", ["bankruptcyChapter12", "BankruptcyChapter12Discharged", "bankruptcyChapter7", "BankruptcyChapter7Discharged"])?.hasSubStatus) {
            return false;
        }
        return true;
    }
    return false;
}
export const isValidatePhoneNumber = (input_str) => {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(input_str);
}

export const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

export const checkIfHasPreference = (userPreferences, preference) => {

    if (userPreferences != null) {
        return userPreferences.find(d => d.preferenceTypeLId == preference) != null
    }
    return false
}

export const userHasPreference = (userPreferences, loanPreferenceTypeLID, loanPreferenceValueLID, eventNotificationPreference) => {


    var isPreferenceSelected = false;
    if (userPreferences != null) {
        if (parseInt(loanPreferenceTypeLID) == 300103) {
            // for the payment reminder preference
            isPreferenceSelected = userPreferences.find(x => x.prefenceValueTypeId == loanPreferenceValueLID && parseInt(x.prefenceTypeId) == parseInt(loanPreferenceTypeLID) && parseInt(x.eventNotificationPreference) == parseInt(eventNotificationPreference)) != null;
        } else {
            isPreferenceSelected = userPreferences.find(x => /*x.prefenceValueTypeId == loanPreferenceValueLID &&*/ parseInt(x.prefenceTypeId) == parseInt(loanPreferenceTypeLID) && parseInt(x.eventNotificationPreference) == parseInt(eventNotificationPreference)) != null;
        }

    }
    return isPreferenceSelected;

}

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure=true;samesite=none";
}


export const getCookie = (cname) => {
    try {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    } catch (err) {

    }

}

export const modificationOrDelinquent = (status, modification) => {

    if (modification) {
        return true
    }
    if (paymentStatus(status, "delinquent", [])?.hasStatus) {
        return true
    }
    return false
}


export const genericStatusCheck = (statuses, status, substatuses) => {
    return {
        hasStatus: status.toLowerCase().includes(statuses?.processStatus?.toLowerCase()),
        hasSubStatus: substatuses.find(s => s.toLowerCase() == statuses?.processPhaseStatus?.toLowerCase())
    }
}



export const paymentStatus = (statuses, status, substatuses) => {
    if ("normalservicing".includes(statuses?.processStatus?.toLowerCase())) {
        return {
            hasStatus: status.toLowerCase().includes(statuses?.paymentStatus?.toLowerCase()),
            hasSubStatus: substatuses.find(s => s.toLowerCase() == statuses?.paymentSubStatus?.toLowerCase())
        }
    } else {
        return null;
    }
}


export const ammountPaymentAhead = (statuses, numPayment) => {

    for (var d = numPayment; d <= 100; d++) {
        if (paymentStatus(statuses, "paidahead", [`paidAhead${d}Payments`])?.hasSubStatus) {
            return true
        }
    }
    return false;
}
export const ammountPaymentPastDue = (statuses, numPayment, greater = false, lessthan = false) => {
    if (paymentStatus(statuses, "delinquent", [])?.hasStatus) {
        if (lessthan) {
            for (var d = numPayment; d >= 0; d--) {
                if (paymentStatus(statuses, "delinquent", [`${d}PaymentsPastDue`])?.hasSubStatus) {
                    return true
                }
            }
        }
        if (greater) {

            if (numPayment >= 7 && paymentStatus(statuses, "delinquent", [`7PaymentsPastDue`])?.hasSubStatus) {
                return true
            }

            for (var d = numPayment; d <= 100; d++) {


                if (paymentStatus(statuses, "delinquent", [`${d}PaymentsPastDue`])?.hasSubStatus) {
                    return true
                }
            }
        }

        for (var i = 1; i <= numPayment; i++) {
            if (paymentStatus(statuses, "delinquent", [`${d}PaymentsPastDue`])?.hasSubStatus) {
                return true
            }
        }

        return false
    } else {

        return false;
    }
}


export const checkPasswordValidation = (nPassword) => {
    var newPWFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
    if (nPassword.match(newPWFormat)) {
        return true;
    }
    else {
        return false;
    }
}


export const endsWith = (str, suffix) => {
    return str.toLowerCase().indexOf(suffix.toLowerCase(), str.length - suffix.length) !== -1;
}

export const calulateFees = (obj) => {

}

export const checkForAlphanumericAndSpace = (inputtxt) => {
    var alphanumericAndSpace = new RegExp("^[A-Za-z0-9 ]+$");
    if (inputtxt.match(alphanumericAndSpace) || inputtxt == '') {
        return true;
    }
    else {
        return false;
    }

}



